var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-base",
    {
      attrs: { "hide-required-legend": "" },
      scopedSlots: _vm._u([
        {
          key: "form",
          fn: function () {
            return [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-stepper",
                        {
                          staticClass: "px-8 pt-1 pb-2",
                          attrs: { flat: "" },
                          model: {
                            value: _vm.step,
                            callback: function ($$v) {
                              _vm.step = $$v
                            },
                            expression: "step",
                          },
                        },
                        [
                          _c(
                            "v-stepper-header",
                            { staticClass: "elevation-2 rounded" },
                            [
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 1, step: "1" },
                                },
                                [_vm._v("Enter Contact Info")]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 2, step: "2" },
                                },
                                [_vm._v("Select Carrier Company")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-items",
                            [
                              _c(
                                "v-stepper-content",
                                { staticClass: "px-0", attrs: { step: "1" } },
                                [
                                  _c("form-base", {
                                    attrs: {
                                      header: _vm.header,
                                      "hide-required-legend": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "form",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-form",
                                              {
                                                ref: "stepOneForm",
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.submit.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _c(
                                                        "h3",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "pr-3",
                                                              attrs: {
                                                                color:
                                                                  "grey darken-2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-card-account-details-outline "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " First enter your Carrier Contact info "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "pt-2" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("text-field", {
                                                          attrs: {
                                                            required: true,
                                                            type: "text",
                                                            rules:
                                                              _vm.$validator.rules.required(
                                                                "First Name"
                                                              ),
                                                            label: "First Name",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.fields
                                                                .firstName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fields,
                                                                "firstName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fields.firstName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("text-field", {
                                                          attrs: {
                                                            required: true,
                                                            type: "text",
                                                            rules:
                                                              _vm.$validator.rules.required(
                                                                "Last Name"
                                                              ),
                                                            label: "Last Name",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.fields
                                                                .lastName,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fields,
                                                                "lastName",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fields.lastName",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("text-field", {
                                                          attrs: {
                                                            required: true,
                                                            type: "text",
                                                            name: "email",
                                                            rules: [
                                                              ..._vm.$validator.rules.required(
                                                                "Email"
                                                              ),
                                                              ..._vm.$validator
                                                                .rules.email,
                                                            ],
                                                            "error-messages":
                                                              _vm.$validator
                                                                .errors[
                                                                "email"
                                                              ],
                                                            hint: "Email must be unique",
                                                            "persistent-hint":
                                                              "",
                                                            label: "Email",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.fields.email,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fields,
                                                                "email",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fields.email",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("text-field", {
                                                          attrs: {
                                                            type: "text",
                                                            rules:
                                                              _vm.$validator
                                                                .rules.phone,
                                                            label:
                                                              "Phone Number",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.fields.phone,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fields,
                                                                "phone",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fields.phone",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "pb-16" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("v-checkbox", {
                                                          staticClass:
                                                            "ml-0 shrink",
                                                          attrs: {
                                                            color: "primary",
                                                            label:
                                                              "Favorite this contact for every warehouse, including future ones",
                                                            "data-testid":
                                                              "create-carrier-favorite",
                                                            disabled:
                                                              _vm.isWarehouseRestricted,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.fields
                                                                .isOrgFavorite,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fields,
                                                                "isOrgFavorite",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fields.isOrgFavorite",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { staticClass: "px-0", attrs: { step: "2" } },
                                [
                                  _c("form-base", {
                                    attrs: {
                                      header: _vm.header,
                                      "hide-required-legend": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "form",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-form",
                                              {
                                                ref: "stepTwoForm",
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.submit.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _c(
                                                        "h3",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey darken-2 pb-2",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-domain"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " Now select the Carrier Company: "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-row",
                                                  { staticClass: "pt-4" },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c("company-select", {
                                                          ref: "companySelect",
                                                          attrs: {
                                                            "entry-point":
                                                              _vm.entryPoint,
                                                            placeholder:
                                                              "Search for a company name, scac, usdot or mc...",
                                                            rules:
                                                              _vm.$validator.rules.required(
                                                                "Carrier Company"
                                                              ),
                                                            "hide-icon": "",
                                                            required: "",
                                                          },
                                                          on: {
                                                            companyChanged:
                                                              _vm.updateCompany,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.fields
                                                                .companyId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fields,
                                                                "companyId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fields.companyId",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-alert",
                            {
                              staticClass: "mx-1",
                              attrs: { color: "amber lighten-5" },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "mb-1" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        small: "",
                                        color: " amber darken-3",
                                      },
                                    },
                                    [_vm._v("mdi-information")]
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      " This carrier will receive appointment email notifications if enabled. "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("p", { staticClass: "mt-1 mb-0 ml-7" }, [
                                _vm._v(
                                  " To schedule a test appointment, please use an email domain ending in “@example.com”. "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "form-actions",
          fn: function () {
            return [
              _c("action-group", {
                attrs: {
                  "confirm-label":
                    _vm.step === 1 ? "next" : "Add Carrier Contact",
                  "cancel-icon": null,
                  "disable-confirm":
                    _vm.step === 1
                      ? !_vm.isStepOneComplete
                      : !_vm.isStepTwoComplete,
                },
                on: {
                  confirm: function ($event) {
                    _vm.step === 1 ? _vm.submitStepOne() : _vm.submitStepTwo()
                  },
                  cancel: function ($event) {
                    return _vm.$emit("close")
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "additional-actions",
                    fn: function () {
                      return [
                        _vm.step !== 1
                          ? _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.step = 1
                                  },
                                },
                              },
                              [_vm._v("Previous")]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("v-divider")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }